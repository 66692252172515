import React, { useEffect, useRef } from 'react';

const CubeAnimation = (props) => {
  const canvasRef = useRef(null);

  const width = 27;
  const height = 27;
  const distanceFromCam = 100;
  const K1 = 40;
  const incrementSpeed = 0.6;
  let A = 0;
  let B = 0;
  let C = 0;

  const calculateX = (i, j, k) => {
    return j * Math.sin(A) * Math.sin(B) * Math.cos(C) -
           k * Math.cos(A) * Math.sin(B) * Math.cos(C) +
           j * Math.cos(A) * Math.sin(C) +
           k * Math.sin(A) * Math.sin(C) +
           i * Math.cos(B) * Math.cos(C);
  };

  const calculateY = (i, j, k) => {
    return j * Math.cos(A) * Math.cos(C) +
           k * Math.sin(A) * Math.cos(C) -
           j * Math.sin(A) * Math.sin(B) * Math.sin(C) +
           k * Math.cos(A) * Math.sin(B) * Math.sin(C) -
           i * Math.cos(B) * Math.sin(C);
  };

  const calculateZ = (i, j, k) => {
    return k * Math.cos(A) * Math.cos(B) -
           j * Math.sin(A) * Math.cos(B) +
           i * Math.sin(B);
  };

  const calculateForSurface = (cubeX, cubeY, cubeZ, ch, ctx, zBuffer, buffer) => {
    const x = calculateX(cubeX, cubeY, cubeZ);
    const y = calculateY(cubeX, cubeY, cubeZ);
    const z = calculateZ(cubeX, cubeY, cubeZ) + distanceFromCam;

    const ooz = 1 / z;

    const xp = Math.floor(width / 2 + K1 * ooz * x);
    const yp = Math.floor(height / 2 + K1 * ooz * y);

    const idx = xp + yp * width;
    if (idx >= 0 && idx < width * height) {
      if (ooz > zBuffer[idx]) {
        zBuffer[idx] = ooz;
        buffer[idx] = ch;
      }
    }
  };

  const renderFrame = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // style
    ctx.fillStyle = "white";
    ctx.font = "bold 8px Courier New";

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const zBuffer = new Array(width * height).fill(0);
    const buffer = new Array(width * height).fill(' ');

    let cubeWidth = 18;
    
    for (let cubeX = -cubeWidth; cubeX < cubeWidth; cubeX += incrementSpeed) {
      for (let cubeY = -cubeWidth; cubeY < cubeWidth; cubeY += incrementSpeed) {
        calculateForSurface(cubeX, cubeY, -cubeWidth, '@', ctx, zBuffer, buffer);
        calculateForSurface(cubeWidth, cubeY, cubeX, '$', ctx, zBuffer, buffer);
        calculateForSurface(-cubeWidth, cubeY, -cubeX, '~', ctx, zBuffer, buffer);
        calculateForSurface(-cubeX, cubeY, cubeWidth, '#', ctx, zBuffer, buffer);
        calculateForSurface(cubeX, -cubeWidth, -cubeY, ';', ctx, zBuffer, buffer);
        calculateForSurface(cubeX, cubeWidth, cubeY, '+', ctx, zBuffer, buffer);
      }
    }

    buffer.forEach((ch, idx) => {
      const x = idx % width;
      const y = Math.floor(idx / width);
      if (ch !== ' ') {
        ctx.fillText(ch, x * 10, y * 10);
      }
    });

    A += 0.05;
    B += 0.05;
    C += 0.01;
  };

  useEffect(() => {
    const interval = setInterval(renderFrame, 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <canvas
      ref={canvasRef}
      width={width * 10}
      height={height * 10}
      style={{ background: 'transparent'}}
    />
  );
};

export default CubeAnimation;