export async function getVerseAndRef(){
    const API_KEY = process.env.REACT_APP_API_KEY;
    const API_URL = process.env.REACT_APP_API_URL;
    
    const fileText = await getHttp("https://dl.dropbox.com/scl/fi/81cpsfu8gmljjmny8i8nz/versesDb.txt?rlkey=w3vk23tphnp0tcd6tzy4ms77z&dl=0")
    const refs = parseFile(fileText)
    const nextRef = getRandom(refs)

    const endpoint = youVersionToBibleApi(nextRef.ref)

    const params = {
        'q': endpoint,
        'indent-poetry': true,
        'include-headings': false,
        'include-footnotes': false,
        'include-verse-numbers': true,
        'include-short-copyright': false,
        'include-passage-references': false
    }

    const headers = {
        Authorization: API_KEY
    }

    const request = await getHttp(API_URL, params, headers)
    const data = JSON.parse(request)
    const text = data.passages.join(" ")
    return {verse: text, ref: data.query}
}

async function getHttp(url, params, headers)
{
    try {
        const response = await fetch(`${url}?${new URLSearchParams(params)}`, { headers: headers});
        const data = await response.text();
        return data;
    } catch (error) {
        console.error('Error fetching the page:', error);
    }
}

function youVersionToBibleApi(youVersion){
    const a = youVersion.split(".")
    a.pop()
    const api = a[0] + "+" + a[1] + ":" + a[2]
    return api
}


function parseFile(fileTxt){
    const lines = fileTxt.split("\n")
    let verses = [];
    lines.forEach(line => {
        const lineSplit = line.split(",")
        if(lineSplit.length !== 2)
            return;
        const ref = lineSplit.at(0)
        const addedAt = lineSplit.at(1)
        verses.push({ref: ref, date: addedAt})
    });
    return verses
}

function getRandom(refs){
    return refs[Math.floor(Math.random() * refs.length)];
}