import { useEffect, useState } from 'react'
import { getVerseAndRef } from '../services/bibleVersesService'

function BibleVersesPage() {
  const [ref, setRef] = useState("")
  const [verse, setVerse] = useState("")

  useEffect(() => {
    const loadData = async () => {
      const { verse, ref } = await getVerseAndRef()
      setVerse(verse)
      setRef(ref)
    }
    loadData()
  }, [])

  return (
    <div style={{display: "flex", flexDirection: "column",textAlign: "center",alignItems:"center",alignContent:"center",justifyContent:"center",backgroundColor: "#161616", height: "100vh"}}>
      <div style={{padding: "40px"}}>
        <h1 style={{fontFamily: 'Georgia', color: "white", margin: 0}}>{ref}</h1>
        <p style={{fontFamily: 'Iosevka Web, Helvetica Neue',color: "white", fontSize: "16px"}}>{verse}</p>
      </div>
    </div>
  )
}

export default BibleVersesPage
