import React from "react";
import "../styles/NavBar.css";

class NavBar extends React.Component {
  render() {
    return (
        <nav fixed="top">
            <div className="nav-content">
                <a href="#">Marco Batista<span className="accent">.</span></a>
                <div className="nav-links">
                    <a href="#">HOME</a>
                    <a href="#about">ABOUT ME</a>
                    <a href="#experience">MY WORK</a>
                    <a href="#projects">PROJECTS</a>
                </div>
            </div>
        </nav>
    );
  }
}

export default NavBar;