import React from 'react';
import '../styles/Intro.css';
import CubeAnimation from './CubeAnimation';

class Intro extends React.Component {
    render(){
        return(
            <div className="section" id="intro">
                <div className='cube-container'>
                    <CubeAnimation />
                </div>
                <div className="intro-message">
                    <h1>
                        HI THERE<span className='accent'>!</span><br/>
                        I<span className='accent'>'</span>M <span className="italic-thin">MARCO</span>
                    </h1>
                </div>
            </div>
        );
    }
}

export default Intro;