import React from 'react';
import '../styles/Footer.css';

class Footer extends React.Component{
    render(){
        return(
            <footer className="footer">
                <p>Built and designed by Marco Batista</p>
                <p>All rights reserved. ©</p>
            </footer>
        );
    }
}

export default Footer